.App {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas: 'header header header ' 'sidenav main main';
  height: 100vh;
  /*grid-gap: 1rem;*/
}

.App {
  box-sizing: border-box;

}

.App > nav {
  grid-area: sidenav;
}

.App > header {
  grid-area: header;
}

.App > main {
  grid-area: main;
  overflow: scroll;
  padding: 40px;
}

.App form {
  text-align: left;
  background-color: rgb(80, 80, 80);
  padding: 16px;
  color: white;
}

.App form input  {
  line-height: 40px;
  padding: 0 16px;
}

.App form label  {
  display: block;
  margin-bottom: 8px;
}

.App > .addNoteFab {
  grid-area: addNoteFab;
}

/*.App > footer {
  grid-area: footer;
}*/

.App li {
  list-style: none;
}

.App ul {
  padding-left: 0;
}

.App a {
  text-decoration: none;
  color: rgb(80, 80, 80);
}

.App button {
  border: none;
}

.ErrorBoundary {
  background-color: rgba(0,0,0,.8);
  background-color: hotpink;
  color: white;
  padding: 16px;
  font-size: 18px;
}



@media screen and (max-width: 600px) {
  .App {
    grid-template-rows: 160px auto auto ;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'header header' 'sidenav sidenav' 'main main';
    /* min-height: 100vh; */
    height: auto
  }


  .App > main {
    overflow: visible;
  }
}



