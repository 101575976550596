

.FolderList__li__nav-link {
  padding: 16px 22px;
  font-size: 22px;
  display: block;
}

.FolderList__li__nav-link svg {
  margin-right: 8px;
}

.FolderList__li__nav-link.active {
  background-color: rgb(80, 80, 80);
  color: yellow;
}

.FolderList__button-wrap {
  display: flex;
  flex-direction: column;
}

.FolderList__button-wrap li {
    margin-bottom: 8px;
}

.FolderList__Btn {
  margin-top: 16px;
}

/*.Button__add-folder {
  border: 1px dashed darkgrey;
  color: darkgrey;
  background-color: rgba(0,0,0,0);
}*/

@media screen and (max-width: 600px) {

  .FolderList__li {
    display: inline;
    margin-right: 16px;
  }

  .FolderList__button-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .FolderList__button-wrap a {
    margin-right: 8px;
  }

}
