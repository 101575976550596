

.Button {
  background-color: darkblue;
  color: white;
  display: inline-block;
  line-height: 40px;
  padding: 8px 16px;
  font-size: 16px;
}

.Button__add-folder {
  border: 1px dashed darkgrey;
  color: darkgrey;
  background-color: rgba(0,0,0,0);
}

.Button__add-folder.light {
  border: 1px dashed lightgrey;
  color: white;
  background-color: rgba(0,0,0,0);
}

.Button__Cancel {
  background-color: violet;
}

.Button:disabled,
.Button[disabled] {
  opacity: .5;
}

