.NoteList {
  text-align: left;
  padding: 22px;
  font-size: 18px;
}


.NoteList li {
  margin-bottom: 8px;
}

.NoteList__button {
  background-color: rgba(0,0,0,0);
  color: darkblue;
}
