.SideNav {
  padding: 16px;
  background-color: lightgrey;
  min-height: 100vh;
}


@media screen and (max-width: 600px) {
  .SideNav {
    min-height: auto;
  }
}
